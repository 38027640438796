<template>
	<v-row no-gutters>
		<v-col class="content-col-container">
			<PDFPreview
				:previewURL="userGuideURL"
			/>
		</v-col>
	</v-row>
</template>


<script>

const api = require('@/utils/api');
import PDFPreview from '@/components/PDFPreview.vue';
import HideFooter from '@/mixins/hide-footer';

export default {
	name: 'UserGuide',

	components: {
		PDFPreview

	},

	mixins: [HideFooter],

	data () {
		return {
			userGuideURL: null
		};
	},
	async beforeMount () {
		const userGuideKey = await api.loadUserGuide();
		if (userGuideKey) {
			const fileURL = `/api/v1/static-file/download?fileKey=${userGuideKey}&userGuide=true`;
			this.userGuideURL = `${fileURL}#toolbar=1`;
		}
		document.body.classList.add('_zoom-preview');
	}
};
</script>
